import { Component } from "@angular/core";
import { FeesService } from "../../fee/services/fees.service";
import { StudentFeeFilter, StudentFilter } from "../../application/enums";
import { UtilService } from "fx-core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { environment } from "../../../../environments/environment";
declare var Razorpay: any;

@Component({
  selector: "app-fee-payment-checkout",
  templateUrl: "student-fee-checkout.component.html",
  styleUrls: ["student-fee-checkout.component.scss"],
})
export class StudentFeeCheckoutComponent {
  groupedFeeDetails: any = [];
  currentStudent: any = null;
  isRazorPayEnable = environment.isRazorPayActive;
  studentId: any = "";

  constructor(
    public feeService: FeesService,
    public utl: UtilService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.studentId = this.route.snapshot.paramMap.get("student");

    this.signIn();
  }

  signIn() {
    var inputData = {
      userName: environment.FeeAdmin.username,
      password: environment.FeeAdmin.password,
    };
    var options: any = {
      action: "idp/login",
      data: inputData,
      type: "post",
    };

    this.utl.http.doAction(options).then((res: any) => {
      this.utl.session.set("id_token", res.data.token);
      const authToken = res.data;
      this.utl.session.setObject("authtoken", authToken);
      this.getStudentById(this.studentId);
    });
  }

  getStudentById(student: number) {
    const inputData = {
      filters: [
        {
          key: StudentFilter.studentId,
          value: student,
        },
      ],
      include: [
        {
          key: "course",
        },
        {
          key: "academicYear",
        },
        {
          key: "section",
        },
      ],
    };
    this.feeService
      .postRequest(inputData, "student/student/getAllStudents")
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.currentStudent = res.data[0];
          this.getFeeGroups(
            this.currentStudent.academicYearId,
            this.currentStudent.studentId
          );
        }else{
          this.utl.alert.showErrorMsg('No Students found')
        }
      });
  }

  getFeeGroups(acadmicYear: number, student: number) {
    const inputData = {
      filters: [
        {
          key: StudentFeeFilter.studentId,
          value: student,
        },
        {
          key: StudentFeeFilter.academicYearId,
          value: acadmicYear,
        },
      ],
      include: [
        {
          key: "feeGroup",
        },
      ],
    };
    this.feeService
      .postRequest(
        inputData,
        "student/studentFee/getStudentFeesForFeeCollection"
      )
      .then((res) => {
        let feeGroups = [];
        let items = res.data;
        for (let item of items) {
          if (item.dueAmount > 0) {
            feeGroups.push(item);
          }
        }

        items = feeGroups;
        this.groupedFeeDetails = items;
      });
  }

  //paywithrazorpay
  payWithRazorPay(feeItem: any) {
    if (feeItem.studentFeeStatusId == 1) {
      //Draft
      this.utl.alert.showErrorMsg("Please generate bill to collect payment!");
      return;
    }
    if (feeItem.studentFeeStatusId == 3) {
      //Cancelled
      this.utl.alert.showErrorMsg(
        "This term fees in cancelled. Please contact admin!"
      );
      return;
    }

    const fee = [
      {
        studentId: this.currentStudent.studentId,
        paymentDate: moment(),
        paymentTime: moment(),
        paymentMode: "online",
        chequeNo: "",
        bank: "",
        bankBranch: "",
        cardNumber: "",
        cardHolderName: "",
        amount: feeItem.dueAmount,
        transactionTypeId: "1",
        refNo: "",
        academicYearId: this.currentStudent.academicYearId,
        courseId: this.currentStudent.courseId,
        batchId: this.currentStudent.batchId,
      },
    ];

    let inputData = {
      data: {
        studentFee: feeItem,
        payments: fee,
      },
    };

    const details = [
      {
        feeId: feeItem.id,
        studentId: this.currentStudent.studentId,
        amount: feeItem.dueAmount,
      },
    ];

    const data: any = {
      studentId: this.currentStudent.studentId,
      deviceType: "web",
      totalAmount: feeItem.dueAmount,
      details,
    };
    if (feeItem.course && feeItem.course.isSplitPayment) {
      data.transfersDetails = {
        share1: feeItem.course.account_1_share,
        share2: feeItem.course.account_2_share,
      };
    }

    const self = this;

    this.feeService
      .postRequest(
        { data },
        "student/studentFeePaymentLog/initiate-rzp-payment"
      )
      .then(
        (res) => {
          if (res.data) {
            let rzpOption: any = {
              key: environment.RazorPayKey, // Enter the Key ID generated from the Dashboard
              amount: res.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: res.data.currency,
              name: "Ideaedu",
              description: feeItem.feeGroup
                ? feeItem.feeGroup.feeGroupName
                : "Miscellaneous Fee",
              order_id: res.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
              handler: function (response) {
                console.log(response, 'response Rzp')
                if (response.razorpay_payment_id) {
                  const Data = {
                    orderRefNo: response.razorpay_payment_id,
                    paymentStatusId: 2,
                    id: res.data.logId,
                  };

                  self.feeService
                    .postRequest(
                      inputData,
                      "student/studentFee/managePayments-no-cashier"
                    )
                    .then((res) => self.savePaymentsCallback(res));
                  self.updateRazorPayPayment(Data);
                }
              },
              prefill: {
                name: `${this.currentStudent.firstName} - ${this.currentStudent.studentIdentifier}`,
                contact: this.currentStudent.fatherMobileNo,
              },
              theme: {
                color: "#20339d",
              },
            };

            if (feeItem.course && feeItem.course.isSplitPayment) {
              const amount1 = Math.ceil(
                res.data.amount * (feeItem.course.account_1_share / 100)
              );
              const amount2 = Math.ceil(
                res.data.amount * (feeItem.course.account_2_share / 100)
              );
              rzpOption = {
                // key: environment.RazorPayKey,
                amount: res.data.amount,
                currency: res.data.currency,
                name: "Ideaedu",
                description: feeItem.feeGroup
                  ? feeItem.feeGroup.feeGroupName
                  : "Miscellaneous Fee",
                transfers: [
                  {
                    account: environment.RazorPayAccount1,
                    amount: amount1,
                    currency: "INR",
                    notes: {
                      branch: "",
                      name: "",
                    },
                    linked_account_notes: ["branch"],
                    on_hold: 0,
                  },
                  {
                    account: environment.RazorPayAccount2,
                    amount: amount2,
                    currency: "INR",
                    notes: {
                      branch: "",
                      name: "",
                    },
                    linked_account_notes: ["branch"],
                    on_hold: 0,
                  },
                ],
                order_id: res.data.id,
                handler: function (response) {
                  console.log(response, ".....Response");
                  if (response.razorpay_payment_id) {
                    const Data = {
                      orderRefNo: response.razorpay_payment_id,
                      paymentStatusId: 2,
                      id: res.data.logId,
                    };

                    self.feeService
                      .postRequest(
                        inputData,
                        "student/studentFee/managePayments-no-cashier"
                      )
                      .then((res) => self.savePaymentsCallback(res));
                    self.updateRazorPayPayment(Data);
                  }
                },
                prefill: {
                  name: `${this.currentStudent.firstName} - ${this.currentStudent.studentIdentifier}`,
                  contact: this.currentStudent.fatherMobileNo,
                },
                theme: {
                  color: "#20339d",
                },
              };
            }
            console.log(rzpOption);

            var options = rzpOption;
            var rzp1 = new Razorpay(options);
            rzp1.open();
          }
        },
        (err) => {
          this.utl.alert.showAlert("Failed to initiate payment.");
        }
      );
  }

  updateRazorPayPayment(data) {
    this.feeService
      .postRequest(
        { data },
        "student/studentFeePaymentLog/updateStudentFeePaymentLog"
      )
      .then((res) => {});
  }

  savePaymentsCallback(res) {
    this.utl.alert.showSuccessMsg();
    this.getFeeGroups(
      this.currentStudent.academicYearId,
      this.currentStudent.studentId
    );
  }
}
