export enum FeePaymentHistoryFilter {
    feePaymentHistoryId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    academicYearId,
    courseId,
    batchId,
    studentFeeId,
    paymentDate,
    paymentMode,
    transactionTypeId,
    chequeNo,
    bank,
    bankBranch,
    cardNumber,
    cardHolderName,
    amount,
    cashierId,
    refundId,
    adjustmentId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    cautionDepositId,
    paymentTime,
    refNo,
    collectionDate,
    ddNo,
    paymentReceiveDate,
    handBillNo
}
export enum StudentAttendanceFilter {
    studentAttendanceId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    studentIdentifier,
    studentName,
    courseId,
    sectionId,
    attendanceDate,
    attendanceStatusId,
    remarks,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    batchId,
    fromDate,
    toDate,
    attendanceType
}
export enum StudentFeeDetailFilter {
    studentFeeDetailId,
    clientId,
    organizationId,
    institutionId,
    studentFeeId,
    studentId,
    academicYearId,
    courseId,
    batchId,
    feeId,
    amount,
    discount,
    welfareAmount,
    netAmount,
    remarks,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    feeServiceId,
    scholarship_type
}
export enum StudentFeeFilter {
    studentFeeId,
    clientId,
    organizationId,
    institutionId,
    invoiceNo,
    invoiceDate,
    studentId,
    academicYearId,
    courseId,
    batchId,
    feeGroupId,
    dueDate,
    delayDays,
    studentFees,
    paidAmount,
    dueAmount,
    studentFeeStatusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    fineAmount
}
export enum StudentleaveFilter {
    studentLeaveId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    courseId,
    batchId,
    sectionId,
    academicYearId,
    requestDate,
    leaveFrom,
    leaveTo,
    leaveTypeId,
    reason,
    leaveRequestStatusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum StudentPromotionFilter {
    studentPromotionId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    courseId,
    batchId,
    sectionId,
    academicYearId,
    studentTestId,
    promotionCourseId,
    promotionBatchId,
    promotionSectionId,
    promotionAcademicYearId,
    promotionRemarks,
    promotedDate,
    promotedById,
    approvedById,
    promotionStatusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum StudentFilter {
    studentId,
    clientId,
    organizationId,
    institutionId,
    titleId,
    genderId,
    firstName,
    lastName,
    email,
    mobileNo,
    dob,
    admissionCategeoryId,
    nationalityId,
    aadhaarNo,
    address,
    imagePath,
    countryId,
    stateId,
    districtId,
    cityId,
    studentIdentifier,
    admissionYearId,
    academicYearId,
    courseId,
    sectionId,
    enrollmentDate,
    studentStatusId,
    studentPortalAccess,
    bloodGroupId,
    fatherName,
    studentUserId,
    fatherMobileNo,
    fatherEmail,
    motherName,
    motherMobileNo,
    motherEmail,
    guardianName,
    guardianMobileNo,
    guardianEmail,
    parentEducation,
    parentProfession,
    parentBloodGroupId,
    parentUserId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    batchId,
    parentPortalAccess,
    feeCategoryId,
    semesterId,
    emisno,
    admittedcourseId,
    comments,
    rollNo
}


export enum StudentAdmissionEnquiryFilter {
    studentAdmissionEnquiryId,
    admissiontypeId,
    clientId,
    organizationId,
    institutionId,
    titleId,
    genderId,
    firstName,
    lastName,
    email,
    mobileNo,
    dob,
    admissionCategeoryId,
    nationalityId,
    aadhaarNo,
    address,
    imagePath,
    countryId,
    stateId,
    districtId,
    cityId,
    admissionYearId,
    academicYearId,
    courseId,
    sectionId,
    admissionDate,
    studentEnquiryStatusId,
    fatherName,
    fatherMobileNo,
    fatherEmail,
    motherName,
    motherMobileNo,
    motherEmail,
    guardianName,
    guardianMobileNo,
    guardianEmail,
    parentEducation,
    parentProfession,
    parentBloodGroupId,
    parentUserId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    batchId,
    feeCategoryId,
    religionId,
    communityId,
    caste,
    studentTypeId,
    otherInfo,
    semesterId,
    isHostel,
    isTransport,
    bloodGroupId
}


export enum StudentAdmissionFeeFilter {
    studentAdmissionFeeId,
    clientId,
    organizationId,
    institutionId,
    invoiceNo,
    invoiceDate,
    studentId,
    academicYearId,
    courseId,
    batchId,
    feeGroupId,
    admissionFees,
    paidAmount,
    dueAmount,
    studentAdmissionStatusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    remarks,
    paymentMethod
}

export enum StudentAttendanceLogFilter {
    studentAttendanceLogId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    studentIdentifier,
    studentName,
    courseId,
    batchId,
    sectionId,
    attendanceDate,
    checkInTime,
    checkOutTime,
    attendanceStatusId,
    studentPresentTypeId,
    location,
    logMode,
    isSynced,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}

export enum StudentDocumentFilter {
    studentDocumentId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    documentTypeId,
    documentDate,
    filePath,
    comments,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}

export enum StudentMemoFilter {
    studentMemoId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    studentName,
    studentIdentifier,
    courseId,
    batchId,
    sectionId,
    memoTypeId,
    memoCreatedById,
    memoDate,
    memoDetails,
    memoStatusId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}


export enum EventEnrollments {
    eventEnrollmentId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    sectionId,
    academicYearId,
    studentId,
    enrolledBy,
    eventId,
    eventDate,
    comments,
    isPresent,
    attendanceRemarks,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}