import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpModule, Http, BrowserXhr  } from '@angular/http';
import {APP_INITIALIZER} from '@angular/core';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppConfig, FxCoreModule } from 'fx-core';
import { FxDesignerModule } from 'fx-designer';
import { ProjectService } from './routes/application/services/project.service';
import { ProjectComponentsModule } from './routes/project-components/project-components.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { WebDataRocksPivot } from './webdatarocks/webdatarocks.angular4';
import { GeneralModule } from './routes/general/general.module';

export function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        {prefix: "./assets/i18n/", suffix: ".json"},
    ]);
}

@NgModule({
    declarations: [
        AppComponent, WebDataRocksPivot
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        HttpModule,
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        ProjectComponentsModule,
        RoutesModule,
        NgProgressModule,
        NgProgressHttpModule,
        GeneralModule,
        FxCoreModule.forRoot({ projectService: ProjectService }),
        FxDesignerModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NgProgressModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgxDatatableModule.forRoot({
            messages: {
              emptyMessage: 'No data to display', // Message to show when array is presented, but contains no values
              totalMessage: '', // Footer total message
              selectedMessage: '' // Footer selected message
            }
          })
    ],
    providers: [
        AppConfig,
        { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function initConfig(config: AppConfig){return () => config.load()}
