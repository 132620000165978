import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { FxCoreModule } from 'fx-core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PortletTodayAttendanceReportComponent } from './studentattendance/portlet-today-attendance-report/portlet-today-attendance-report.component';
import { PortletIrregularStudentComponent } from './studentattendance/portlet-irregular-student/portlet-irregular-student.component';
import { PortletChartClasswiseAttendanceComponent } from './studentattendance/portlet-chart-classwise-attendance/portlet-chart-classwise-attendance.component';
import { PortletAttendanceSummaryComponent } from './studentattendance/portlet-attendance-summary/portlet-attendance-summary.component';
import { PortletMaleFemaleStudentCountComponent } from './studentattendance/portlet-male-female-student-count/portlet-male-female-student-count';
import { PortletCalendarComponent } from './common/portlet-calendar/portlet-calendar.component';
import { StudentAttendanceService } from './services/studentattendance.service';
import { CommonPortletService } from './services/commonportlet.service';
import { EmpDocumentComponent } from './emp-document/emp-document.component';
import { ProjectComponentsModule } from '../project-components/project-components.module';
import { EmpDocumentService } from './services/empdocument.service';
import { EmpDocumentModalComponent } from './emp-document-modal/emp-document-modal.component';
import { StudentDocumentService } from './services/studentdocument.service';
import { StudentDocumentComponent } from './student-document/student-document.component';
import { StudentDocumentModalComponent } from './student-document-modal/student-document-modal.component';
import { SharedStudentRoomAssignmentComponent } from './room-assignment/room-assignment.component';
import { SharedPassengerAssignmentComponent } from './transport-assign/transport-assignment.component';
import { HostelMgmtService } from "../hostelmgmt/services/hostelmgmt.service";
import { StudentService } from "../hostelmgmt/services/student.service";
import { PassengerAssignmentService } from "../transport/services/passenger-assignment.service";
import { AdmissionFeeComponent } from './admission-fee-modal/admission-fee-modal.component';
import { StudentPersonalComponent } from './student-personal/student-personal.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { StudentFeeCheckoutComponent } from './student-fee-checkout/student-fee-checkout.component';
import { FeesService } from '../fee/services/fees.service';

export const generalRoutes: Routes = [
];

@NgModule({
    imports: [
        SharedModule,
        FxCoreModule.forRoot(),
        RouterModule.forChild(generalRoutes),
        ProjectComponentsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
    ],
    declarations: [
        PortletAttendanceSummaryComponent,
        PortletChartClasswiseAttendanceComponent,
        PortletIrregularStudentComponent,
        PortletTodayAttendanceReportComponent,
        PortletMaleFemaleStudentCountComponent,
        PortletCalendarComponent,
        EmpDocumentComponent,
        EmpDocumentModalComponent,
        StudentDocumentComponent,
        SharedStudentRoomAssignmentComponent,
        StudentDocumentModalComponent,
        SharedPassengerAssignmentComponent,
        AdmissionFeeComponent,
        StudentPersonalComponent,
        PaymentSuccessComponent,
        StudentFeeCheckoutComponent,
        PaymentFailComponent
    ],
    exports: [
        RouterModule,
        PortletAttendanceSummaryComponent,
        PortletChartClasswiseAttendanceComponent,
        PortletIrregularStudentComponent,
        PortletTodayAttendanceReportComponent,
        PortletMaleFemaleStudentCountComponent,
        PortletCalendarComponent,
        EmpDocumentComponent,
        EmpDocumentModalComponent,
        StudentDocumentComponent, SharedStudentRoomAssignmentComponent,
        SharedPassengerAssignmentComponent,
        StudentDocumentModalComponent,
        AdmissionFeeComponent,
        StudentPersonalComponent

    ],
    entryComponents: [
        EmpDocumentModalComponent,
        StudentDocumentModalComponent,
        AdmissionFeeComponent,
        StudentPersonalComponent
    ],
    providers: [
        StudentAttendanceService,
        CommonPortletService,
        EmpDocumentService,
        StudentDocumentService,
        StudentService,
        PassengerAssignmentService,
        HostelMgmtService,
        FeesService
    ]
})

export class GeneralModule { }
